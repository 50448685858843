import React, { useState,useEffect, useRef, useCallback } from 'react';

const testimonials = [
  {
    id: 1,
    name: "Erez Marchini",
    position: "CEO at Goupss",
    image: "/model2.jpeg",
    link:"https://www.linkedin.com/in/erez-marchini/",
    quote: "We generate content for our clients on a weekly basis, and working with Fluffy has made things a lot easier for us. Their AI tools help us put out higher quality content way faster than we could before, and the platform is very straightforward to use. Next gen tool for content creation at any scale!"
  },
  {
    id: 2,
    name: "Albert Bitton",
    position: "Event Organizer, Berlin",
    image: "/model3.png",
    link:"https://www.facebook.com/avi.bitton.31",
    quote: "As I am expanding the areas of my work life, time are filled up with a lot of tasks that require me to look for a solution to clear time and still be efficient with promoting my services in social media. I used Fluffy to generate content, it used text and photos from my past content and generated great new content for me. Saving me a lot of time."
  },
  // {
  //   id: 3,
  //   name: "Jane Doe",
  //   position: "Marketing Director, XYZ Corp",
  //   image: "/model3.png",
  //   quote: "Fluffy has revolutionized our content creation process. The AI-generated content is incredibly human-like, and our clients can't tell the difference. It's like having an extra team member dedicated to producing high-quality content around the clock."
  // }
];


export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliding, setSliding] = useState(false);
  const [slideDirection, setSlideDirection] = useState(0);
  const [contentHeight, setContentHeight] = useState('auto');
  const timeoutRef = useRef(null);
  const contentRef = useRef(null);

  const debounce = (func, delay) => {
    return (...args) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => func(...args), delay);
    };
  };

  const handleTransition = useCallback(
    debounce((direction) => {
      setSliding(true);
      setSlideDirection(direction);
      
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + direction + testimonials.length) % testimonials.length);
        setSliding(false);
        setSlideDirection(0);
      }, 300);
    }, 300),
    []
  );

  const handlePrev = () => handleTransition(-1);
  const handleNext = () => handleTransition(1);

  const getTestimonialIndex = (shift) => {
    return (currentIndex + shift + testimonials.length) % testimonials.length;
  };

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth < 768 && contentRef.current) {
        const height = contentRef.current.offsetHeight;
        setContentHeight(`${height}px`);
      } else {
        setContentHeight('auto');
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [currentIndex]);

  return (
    <div className="bg-white py-16">
      <h2 className="text-4xl font-bold leading-[40px] text-center bg-gradient-to-r from-[#2559EC] to-[#7388F7] bg-clip-text text-transparent mb-12">Our Clients' Words</h2>
      
      <div className="relative mx-auto px-4 overflow-hidden" style={{ height: contentHeight }}>
        <div className="flex items-center justify-center h-full min-h-[445px]">
          {[-1, 0, 1].map((shift) => {
            const testimonial = testimonials[getTestimonialIndex(shift)];
            return (
              <div
                key={`${testimonial.id}-${shift}`}
                className={`transition-all duration-300 ease-in-out absolute 
                  ${shift === 0 ? 'z-20 opacity-100' : 'z-10 opacity-50 hidden md:block'}
                  w-[90%] md:w-[calc(60%-20px)]`}
                style={{
                  transform: `translateX(${(shift - slideDirection) * 106}%)`,
                }}
                ref={shift === 0 ? contentRef : null}
              >
                <div className="bg-white rounded-xl shadow-lg overflow-hidden h-full">
                  <div className="md:hidden">
                    <img src={testimonial.image} alt={testimonial.name} className="w-full h-[445px] object-cover object-center" />
                  </div>
                  <div className="flex flex-col md:flex-row h-full rounded-xl">
                    <div className={`p-8 box-border flex flex-col md:w-1/2 justify-center relative ${
                      shift === 0 ? 'bg-[#5440D8] text-white' : 'bg-gray-100 text-gray-800'
                    }`}>
                      <div className="absolute top-10 right-6 text-6xl opacity-20 pointer-events-none">
                        <img src="apstr.svg" alt="" className="w-12 h-12" />
                      </div>
                      <p className="text-sm mb-4 relative z-10">"{testimonial.quote}"</p>
                      <div>
                        <a href={testimonial.link} target="_blank" rel="noopener noreferrer"><p className="font-bold">{testimonial.name}</p></a>
                        <p className="text-xs">{testimonial.position}</p>
                      </div>
                    </div>
                    <div className="hidden md:block md:w-1/2 rounded-r-xl">
                      <img 
                        src={testimonial.image} 
                        alt={testimonial.name} 
                        className="w-full h-full object-cover object-center rounded-r-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        
        <button 
          onClick={handlePrev} 
          className="absolute left-4 md:left-[18%] top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-30"
          disabled={sliding}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <button 
          onClick={handleNext} 
          className="absolute right-4 md:right-[18%] top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-30"
          disabled={sliding}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
}