import React from 'react'
import { useNavigate } from 'react-router-dom'


const Thankyou = () => {
  const navigate = useNavigate()
  return (
    <div className='w-full h-screen flex flex-col justify-center items-center bg-[rgb(37,89,236)]' >
        <div className='flex justify-center items-center text-white text-[56px]'>
            Thank You!
        </div>
        <div className='w-[200px] h-[50px] border rounded-full flex justify-center items-center bg-white text-[rgb(37,89,236)] mt-5 cursor-pointer' onClick={()=>{navigate('/')}}>Back to Home</div>
    </div>
  )
}

export default Thankyou